<template>
<div class="business" ref="business" @scroll="testScroll">
  <gt-header checked="swhz"></gt-header>
<banner></banner>
  <company-profile></company-profile>
  <course></course>
  <gt-map></gt-map>
  <gt-footer></gt-footer>
  <div class="toTop" @click="toTop" v-if="topPk">
    <i class="el-icon-arrow-up topArrow"></i>
  </div>
  <div class="leaveMessage" @click="messageChange" v-if="!messagePk">
    <img src="../../assets/newImgs/aboutMe/jqr.png">
  </div>
  <div class="outerBg" v-if="messagePk">
  <div class="cancelBg" @click="messageChange"></div>
    <div class="leaveMain">
     <div class="formMain">
       <div class="formLine">
         <div class="lineTitle">您的称呼:</div>
         <input type="text" placeholder="请输入您的名称！" v-model="customerName" />
       </div>
       <div class="formLine">
         <div class="lineTitle">公司名称:</div>
         <input type="text" placeholder="请输入公司名称！" v-model="companyName" />
       </div>
       <div class="formLine">
         <div class="lineTitle">联系电话:</div>
         <input type="text" placeholder="请输入联系电话！" v-model="telephone" />
       </div>
       <div class="formLine">
         <div class="lineTitle">电子邮箱:</div>
         <input type="text" placeholder="请输入邮箱地址！" v-model="email" />
       </div>
      <div class="subBtn">提交</div>
     </div>
    </div>
  </div>
</div>
</template>

<script>
import GtHeader from "../../components/gtHeader";
import GtFooter from "../../components/gtFooter";
import {isInViewPort} from "../../mixin/mixin";
import Banner from "../../components/aboutMe/banner"
import CompanyProfile from "../../components/aboutMe/companyProfile";
import Course from "../../components/aboutMe/course";
import  GtMap from "../../components/aboutMe/gtMap"
export default {
  name: "business",
  components: {Course, CompanyProfile, Banner, GtMap, GtFooter, GtHeader},
  mixins:[isInViewPort],
  data(){
    return{
      topPk:false,
      winPk:false,
      agentPk:false,
      messagePk:false,
      customerName:"",
      companyName:"",
      telephone:"",
      email:""
    }
  },
  methods:{
    toTop(){
      let timer = setInterval(() => {
        let scr=this.$refs.business.scrollTop;
        let ispeed = Math.floor(-scr/ 5)
        this.$refs.business.scrollTop=scr+ispeed;
        if (scr === 0) {
          clearInterval(timer)
        }
      }, 16)

    },
    testScroll(){

      if(this.$refs.business.scrollTop>=500){
        this.topPk=true
      }else{
        this.topPk=false

      }
    },
    scrollToTop () {

    },
    messageChange(){
      this.messagePk=!this.messagePk;
    }

  }
}
</script>

<style lang="scss" scoped>
.business{
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar{
    width:10px;
  }
  //滚动条小方块
  &::-webkit-scrollbar-thumb{
    border-radius:5px;
    background:rgb(167, 198, 156);
  }
  //滚动条轨道
  &::-webkit-scrollbar-track{
    border-radius:0px;
    height:100px;
    background:rgba(167, 198, 156,0.3);
  }
}
.toTop{
  position: fixed;
  bottom:200px;
  right:200px;
  width: 60px;
  height: 60px;
  background: #EAEAEA;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  .topArrow{
    cursor: pointer;
    color: white;
    font-size: 40px;
  }
}
.leaveMessage{
  position: fixed;
  right: 11px;
  bottom: 150px;
  width: 160px;
  height: 152px;
  cursor: pointer;
  img{
    width: 160px;
    height: 152px;
  }
}
.outerBg{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  .cancelBg{
    position: relative;
    width: 100vw;
    height: 100vh;
  }
  .leaveMain{
    position: absolute;
    right: 11px;
    bottom: 0px;
    width: 600px;
    height: 600px;
    background: url("../../assets/newImgs/aboutMe/messageBg.png");
    background-size: 100% 100%;
  }
}
.formMain{
  margin: 0 auto;
  width: 300px;
  height: 280px;
  transform: translateX(-28px) ;
  margin-top: 230px;
  padding-top: 10px;
  box-sizing: border-box;
  padding-left: 35px;

}
.subBtn{
  position: absolute;
  right: 40px;
  bottom: 0;
  background: rgb(255, 91, 87);
  font-size: 12px;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.formLine{
  width: 250px;
  display: flex;
  font-size: 16px;
  margin-top: 20px;
  .lineTitle{
    white-space: nowrap;
    margin-right: 5px;
  }

  input{
    outline: none;
    font-size: 16px;
     border-style: none;
    height: 23px;
    width: 150px;
    border-bottom: 1px dashed gray;
  }
  input:checked{
    border-style: none;
  }

}
</style>
