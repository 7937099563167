<template>
<div class="course" id="course">
<div class="titleMain">
  <img src="../../assets/newImgs/aboutMe/leftArrow.png">
  <span> 关于国通</span>
  <img src="../../assets/newImgs/aboutMe/rightArrow.png">
</div>
  <div class="txtMain">
    依托与高校科研的紧密协作，国通电气已拥有业内领先的自助核心技术和可持续研发能力，拥有多项专利与软著，先后为国家电网公司、政府综合服务机构、医疗机构、院校提供的行业优化解决方案均获好评，其中应用于国家电网公司的餐饮整体解决方案获得国网浙江公司创新管理大赛二等奖，还因助力企业节源节流、管理创新，接受了浙江卫视的专访；所开发项目获浙江未来科技城·梦想小镇创业先锋营金钥匙奖，并入选浙江未来科技城（海创园）海外高层次人才创新创业项目。
  </div>
  <div class="ryList">
    <img src="../../assets/newImgs/aboutMe/ry1.png" alt="">
    <img src="../../assets/newImgs/aboutMe/ry2.png" alt="">
    <img src="../../assets/newImgs/aboutMe/ry3.png" alt="">
    <img src="../../assets/newImgs/aboutMe/ry4.png" alt="">
    <img src="../../assets/newImgs/aboutMe/ry5.png" alt="">
    <img src="../../assets/newImgs/aboutMe/ry6.png" alt="">

  </div>
</div>
</template>

<script>
export default {
  name: "course"
}
</script>

<style lang="scss" scoped>
.course{
  position: relative;
  width: 1920px;
  height: 650px;
  background: url("../../assets/newImgs/aboutMe/deepGreenBg.png");
  background-size: 100% 100%;
  padding-top: 50px;
  box-sizing: border-box;
}
.titleMain{
  width: 1200px;
  display: flex;
  justify-content: center;
  color: white;
  margin: 0 auto;
  font-size:36px;
      align-items: center;
  span{
    margin: 0 20px;
  }
  img{
    width: 290px;
  }
}
.txtMain{
  width: 600px;
  margin: 0 auto;
  margin-top: 80px;
  color: white;
  font-size: 14px;
}
.ryList{
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 70px;
  img{
    width: 146px;
    height: 210px;
    transition: 0.5s;

  }
  img:hover{
    transform: scale(2);
    transition: 0.5s;
  }
}
</style>
