<template>
<div class="gtMap" id="gtMap">
<div class="mapTitle">联系我们</div>
  <div class="boxLine">
    <div class="boxEver">
      <div class="boxImg">
        <img src="../../assets/newImgs/aboutMe/gongsi.png">
      </div>
      <div class="boxTxt">
        浙江国通电气科技有限公司
      </div>
    </div>
    <div class="boxEver">
      <div class="boxImg">
        <img src="../../assets/newImgs/aboutMe/dizhi.png">
      </div>
      <div class="boxTxt">
        杭州市余杭区仓前街道欧美金融城2幢606室
      </div>
    </div>
    <div class="boxEver">
      <div class="boxImg">
        <img src="../../assets/newImgs/aboutMe/dianhua-2.png">
      </div>
      <div class="boxTxt">
        0571-88362200
      </div>
    </div>
  </div>
<el-amap vid="amapDemo" class="map" :map-style="mapStyle"  :center="center" :zoom="zoom" :plugin="plugin" >
  <el-amap-marker
      v-for="marker in markers"
      :position="marker.position"
      :key="marker.id"
      :events="marker.events"
      :icon="marker.icon"
  ></el-amap-marker>
  <el-amap-info-window
      v-if="wind"
      :position="wind.position"
      :visible="wind.visible"
      :offset="wind.offset"
  >
    <div>
      <div v-html="wind.content"></div>
      <!--          <button class="infoBtn" @click="toScreen(window.projectId)">查看详情</button>-->
    </div>

  </el-amap-info-window>
</el-amap>
</div>
</template>

<script>
export default {
  name: "gtMap",
  data(){
    return{
      center: [120.003099,30.280265],
      zoom:16,
      // mapStyle: "amap://styles/ea98c2998063d19d42bbab42ea7dfbd2", //设置地图样式
      mapStyle:"",
      plugin: [
        'ToolBar', {
        pName: 'MapType',
        defaultType: 0,
        events: {

        }
      }
      ],
      points:[
        {
          position:[120.003099,30.280265],
          img:require("../../assets/imgs/business/company.png")
        },

      ],
      winds:[

      ],
      wind:"",
      markers:[
        // {
          // position:[120.005294,30.287801],

        // }
      ]
    }
  },
  methods:{
    mapInit() {
  let that = this;
      this.points.forEach((item, index) => {
        this.winds.push({
          position: [item.position[0], item.position[1]],
              content:'<div style="width: 15vw;height: 10vw;"><img style="width: 15vw;height: 10vw;" src='+item.img+' /></div>'
          ,
          offset: [0, 0],//窗体偏移
          visible: false,//初始是否显示

        })
        this.markers.push({
          position: [item.position[0], item.position[1]],
          events: {
            click(e) {
              that.winds.forEach(wind => {
                wind.visible = false; //关闭窗体
                // that.icon="";
              });
              that.wind = that.winds[index];
              that.$nextTick(() => {
                that.wind.visible = true;//点击点坐标，出现信息窗体

              });
            }
          },
        })

      })
    }

  },
  mounted() {
    this.mapInit();

  },
  watch:{
    winds(){

    }
  }
}
</script>

<style lang="scss" scoped>

.gtMap{
  position: relative;
  width: 1920px;
  height: 1100px;
  padding-top: 100px;
  box-sizing: border-box;
}
.mapTitle{
  margin: 0 auto;
  width: 220px;
  height: 100px;
  border-radius: 15px;
  border: 1px solid #EEAA3D;
  text-align: center;
  line-height: 100px;
  color: #EEAA3D;
  transform: translateX(30px);
  font-size: 22px;
}
.map{
  margin: 0 auto;
  width: 1200px;
  height: 600px;
}
/deep/ .amap-info-content{
  //background: none;
  position: relative;
  background: rgba(0,0,0,0.1) !important;

}


/deep/ .bottom-center /deep/.amap-info-sharp{
  border-style: none !important;
}

.boxLine{
  margin: 0 auto;
  width: 1200px;
  height:298px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.boxEver{
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  color: #666666;
  .boxImg{
    width: 58px;
    height: 58px;
    img{
      width: 58px;
      height: 58px;
    }
  }
  .boxTxt{
    margin-top: 20px;
  }
}
</style>
