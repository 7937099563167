<template>
<div class="companyProfile" id="companyProfile">
<div class="profileMain">
<div class="profileTitle">
  <img src="../../assets/newImgs/aboutMe/Companyprofile.png" />
  公司简介
</div>
  <div class="proCenter">
<div class="proLeft">
  <div class="img1">
    <img src="../../assets/newImgs/aboutMe/img2.png">
  </div>
  <div class="img2">
    <img src="../../assets/newImgs/aboutMe/img1.png">
  </div>
</div>
    <div class="proRight">
    <div class="lineOne">改革创新，降本增效，数字化餐饮管理的先导者</div>
      <div class="lineTwo">
        <div class="fakerBox"></div>
        <div class="txtBox">我们是国通电气</div>
      </div>
      <div class="lineThree">
        浙江国通电气科技有限公司是一家集互联网软件、人工智能、精密硬件设备制造与一体的科创型企业；是领先的政府服务机构、大中型企事业单位的餐饮管理系统优化解决方案提供商。致力于帮助政府及企业管理数字化赋能、改革创新、降本增效。
      </div>
      <div class="lineThree">
<!--        国通电气拥有业内领先的自主核心技术和可持续研发能力，为国家电网公司，政府综合服务机构，医疗机构，学校等提供合适的细分产品与专业的行业解决方案。国通电气部署在国家电网公司的整体解决方案获得国网浙江公司创新管理大赛二等奖，还因帮助企业节源节流，管理创新，接受了浙江卫视的采访。-->
      </div>

    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  name: "companyProfile",
  data(){
    return{

    }
  }
}
</script>

<style lang="scss" scoped>
.companyProfile{
  position: relative;
  width: 1920px;
  height: 640px;

}
.profileMain{
  width: 1200px;
  height: 640px;
  margin: 0 auto;
  padding-top: 50px;
  box-sizing: border-box;
}
.profileTitle{
  position: relative;
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 36px;
  color: #333333;
  img{
    width: 300px;
  }
}
.proCenter{
  margin: 0 auto;
  width: 1200px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.proLeft {
  position: relative;
  width: 300px;
  height: 350px;

  .img1 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 230px;
    height: 300px;

    img {
      width: 230px;
      height: 300px;
    }
  }

  .img2 {
    position: absolute;
    right: 0;
    top: 0;
    width: 230px;
    height: 300px;

    img {
      width: 230px;
      height: 300px;
    }
  }
}
.proRight{
  width: 650px;
  min-height:300px ;
  margin-left: 50px;
  .lineOne{
    color: #333333;
    font-size: 24px;

    font-weight: 500;
    margin-top: 40px;
  }

  .lineTwo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #EEAA3D;
    font-size: 36px;
    margin-top: 10px;
    margin-bottom: 50px;

    .fakerBox{
      width: 160px;
      height: 5px;
      background: #EEAA3D;
      margin-right: 10px;
    }
    .txtBox{
      color: #EEAA3D;
      font-size: 36px;
      font-weight: 800;
    }
  }
  .lineThree{
    color: #666666;
    font-size: 14px;
    margin-top:20px;

  }
}
</style>
